import React from "react";
import styled from "styled-components";

import { OverlayTrigger, Button } from "react-bootstrap";
import { useWallet } from "wallets/wallet";
import { getEtherscanAddressUrl, shortenAddr } from "web3/utils";

import { ReactComponent as RadioSvg } from "assets/svg/icons/radio.svg";
import { ReactComponent as CreditCardSvg } from "assets/svg/icons/credit-card.svg";
import { ReactComponent as GlobekSvg } from "assets/svg/icons/globe.svg";
import { ReactComponent as UserSvg } from "assets/svg/icons/user.svg";

import { useAsyncEffect } from "hooks/useAsyncEffect";

import { useSelector } from "react-redux";
import { networkSelector } from "../../slices/network";

import s from "./styles0.module.css";

const ContacthBtn = styled(Button)`
  background-color: transparent;
  font-size: 16px;
  border-radius: 84px;
  background: #ff9600;
  padding: 10px;
  border: 1px solid #ff9600;
  width: 250px;
  &:hover {
    background-color: transparent;
    color: gray;
    border: 1px solid #ff9600;
  }
  &:focus {
    color: gray;
    background-color: transparent;
    box-shadow: 0 0 0 0 rgb(38 143 255 / 50%);
    border: 1px solid #ff9600;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: transparent;
    color: gray;
    border: 1px solid #ff9600;
  }
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 5%);
  }
`;

const ConnectedWallet: React.FunctionComponent = (props) => {
  const wallet = useWallet();
  const { network } = useSelector(networkSelector);

  function handleWalletConnect() {
    wallet.showWalletsModal();
  }

  // useAsyncEffect(async () => {
  //   wallet.disconnect();
  // }, [network]);

  const handleWalletDisconnect = () => {
    if (wallet.isActive) wallet.disconnect();
  };

  if (wallet.connecting) {
    return (
      <div className={s.component}>
        <OverlayTrigger
          placement="bottom"
          trigger="click"
          rootClose
          overlay={
            <div
              style={{
                width: "255px",
                background: "#0a0a0a",
                zIndex: 9999,
                border: "1px solid #ff9600",
                marginTop: "15px",
              }}
            >
              <div className={s.stats} style={{ padding: "20px 20px 0 20px" }}>
                <div className={s.statRow}>
                  <div>
                    <RadioSvg className={s.statIcon} />
                  </div>
                  <div>
                    <span className={s.statName}>Status</span>
                  </div>
                  <div>
                    <div className={s.statTag}>Connecting...</div>
                  </div>
                </div>
                <div className={s.statRow}>
                  <div>
                    <CreditCardSvg className={s.statIcon} />
                  </div>
                  <div>
                    <span className={s.statName}>Wallet</span>
                  </div>
                  <div>
                    <span className={s.statValue}>
                      {wallet.connecting?.name}
                    </span>
                  </div>
                </div>
              </div>
              <div className={s.disconnectBtnRow}>
                <Button
                  type="ghost"
                  className={s.disconnectBtn}
                  onClick={handleWalletDisconnect}
                >
                  Disconnect
                </Button>
              </div>
            </div>
          }
        >
          <div className={s.connectBtn}>Connecting...</div>
        </OverlayTrigger>
      </div>
    );
  }

  if (!wallet.isActive) {
    return (
      <div className={s.component}>
        {/* <ContacthBtn onClick={() => handleWalletConnect()}>
          CONNECT WALLET
        </ContacthBtn> */}

        {/* <div className="dropdown" onClick={() => handleWalletConnect()}>
          <button
            aria-haspopup="true"
            aria-expanded="false"
            id="dropdown-basic-button2"
            type="button"
            className="dropdown-toggle btn btn-primary"
          >
            <div
              className="d-flex align-items-center gap-2 position-relative"
              style={{ bottom: "5px", fontSize: "12px" }}
            >
              <img
                src="https://app.dypius.com/static/media/walletIcon.71c13b49.svg"
                alt=""
                className="position-relative"
              />
              <span className="connecttitle d-none d-lg-flex">
                Connect Wallet
              </span>
            </div>
          </button>
        </div> */}

        <button className={s.connectbtn} onClick={() => handleWalletConnect()}>
          <i className="fas fa-wallet"></i> Connect Wallet
        </button>

      </div>
    );
  }

  return (
    <div className={s.component}>
      <div className={s.walletBox}>
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          rootClose
          overlay={
            <div
              style={{
                background: "#0a0a0a",
                zIndex: 9999,
                border: "2px solid #651fff",
                marginTop: "5px",
              }}
            >
              <div className={s.walletHeader}>
                <div className={s.walletAvatarCol}>
                  <UserSvg className={s.walletAvatar} />
                </div>
                <div>
                  <a
                    href={getEtherscanAddressUrl(
                      wallet.account!,
                      network === "bsc"
                        ? Number(process.env.REACT_APP_WEB3_CHAIN_ID)
                        : Number(process.env.REACT_APP_WEB3_MATIC_CHAIN_ID)
                    )}
                    className={s.walletPreviewHash}
                    target="_blank"
                  >
                    {shortenAddr(wallet.account!, 8, 8)}
                  </a>
                </div>
              </div>
              <div className={s.stats}>
                <div className={s.statRow}>
                  <div>
                    <RadioSvg className={s.statIcon} />
                  </div>
                  <div>
                    <span className={s.statName}>Status</span>
                  </div>
                  <div>
                    <span className={s.statTag}>Connected</span>
                  </div>
                </div>
                <div className={s.statRow}>
                  <div>
                    <CreditCardSvg className={s.statIcon} />
                  </div>
                  <div>
                    <span className={s.statName}>Wallet</span>
                  </div>
                  <div>
                    <span className={s.statValue}>
                      {wallet.connector?.name}
                    </span>
                  </div>
                </div>
                <div className={s.statRow}>
                  <div>
                    <GlobekSvg className={s.statIcon} />
                  </div>
                  <div>
                    <span className={s.statName}>Network</span>
                  </div>
                  <div>
                    <span className={s.statValue}>{wallet.networkName}</span>
                  </div>
                </div>
              </div>
              <div className={s.disconnectBtnRow} style={{ cursor: "pointer" }}>
                <Button
                  type="ghost"
                  className={s.disconnectBtn}
                  onClick={handleWalletDisconnect}
                >
                  Disconnect
                </Button>
              </div>
            </div>
          }
        >
          <div className={s.walletPreview}>
            <div>
              <span className={s.walletPreviewHashOnBtn}>
                {shortenAddr(wallet.account!, 8, 8)}
              </span>
            </div>
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default ConnectedWallet;
