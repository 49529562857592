import React from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { ModalProps } from "react-overlays/Modal";
import { useWallet } from "wallets/wallet";
import { getNetworkName } from "web3/utils";

import { useSelector } from "react-redux";
import { networkSelector } from "../../slices/network";

import "assets/css/modal.css"

declare let window: any;

export type UnsupportedChainModalProps = ModalProps & {};

// const WEB3_CHAIN_ID = Number(process.env.REACT_APP_WEB3_CHAIN_ID);

const CustomBtn = styled.button`
  color: white !important;
  border: 1px solid white;
  border-radius: 40px;
  width: 180px;
  height: 40px;
  margin: 20px auto 0;
  text-align: center;
  font-size: 13px;
  padding: 10px 30px;
  @media (max-width: 767px) {
    width: 100%;
  }
  background:none;
`;

const UnsupportedChainModal: React.FunctionComponent<UnsupportedChainModalProps> =
  (props) => {
    const { ...modalProps } = props;

    const wallet = useWallet();
    const { network } = useSelector(networkSelector);

    // console.log("================---==", wallet);

    const onSwitchNetwork = async (networkName: string) => {
      const networks: any = {
        bsc: {  //zkSync Era Mainnet
          chainId: `0x${Number(324).toString(16)}`,
          chainName: " zkSync Era Mainnet",
          nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: ["https://mainnet.era.zksync.io"],
          blockExplorerUrls: ["https://explorer.zksync.io/"],
        },

        // bsc: {  //arbitrum
        //   chainId: `0x${Number(42161).toString(16)}`,
        //   chainName: "Arbitrum One",
        //   nativeCurrency: {
        //     name: "ETH",
        //     symbol: "ETH",
        //     decimals: 18,
        //   },
        //   rpcUrls: ["https://arb1.arbitrum.io/rpc/"],
        //   blockExplorerUrls: ["https://arbiscan.io/"],
        // },

        // bsc: {  //eth
        //   chainId: `0x${Number(1).toString(16)}`,
        //   chainName: "Ethereum Mainnet",
        //   nativeCurrency: {
        //     name: "ETH",
        //     symbol: "ETH",
        //     decimals: 18,
        //   },
        //   rpcUrls: ["https://mainnet.infura.io/v3/"],
        //   blockExplorerUrls: ["https://etherscan.io/"],
        // },
        polygon: {
          chainId: `0x${Number(137).toString(16)}`,
          chainName: "Polygon Mainnet",
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
          },
          rpcUrls: ["https://polygon-rpc.com/"],
          blockExplorerUrls: ["https://polygonscan.com/"],
        },
        // bsc: {
        //   chainId: `0x${Number(56).toString(16)}`,
        //   chainName: "Binance Smart Chain Mainnet",
        //   nativeCurrency: {
        //     name: "Binance Chain Native Token",
        //     symbol: "BNB",
        //     decimals: 18,
        //   },
        //   rpcUrls: [
        //     "https://bsc-dataseed1.binance.org",
        //     "https://bsc-dataseed2.binance.org",
        //     "https://bsc-dataseed3.binance.org",
        //     "https://bsc-dataseed4.binance.org",
        //     "https://bsc-dataseed1.defibit.io",
        //     "https://bsc-dataseed2.defibit.io",
        //     "https://bsc-dataseed3.defibit.io",
        //     "https://bsc-dataseed4.defibit.io",
        //     "https://bsc-dataseed1.ninicoin.io",
        //     "https://bsc-dataseed2.ninicoin.io",
        //     "https://bsc-dataseed3.ninicoin.io",
        //     "https://bsc-dataseed4.ninicoin.io",
        //     "wss://bsc-ws-node.nariox.org",
        //   ],
        //   blockExplorerUrls: ["https://bscscan.com"],
        // },
      };

      try {
        if (!window.ethereum) throw new Error("No crypto wallet found");
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              ...networks[networkName],
            },
          ],
        });
      } catch (err) {
        // setError(err.message);
      }
    };

    return (
      <Modal centered closable={false} footer={[]} {...modalProps}>
        <Modal.Header closeButton className="modalHeader">
          <Modal.Title id="contained-modal-title-vcenter">
            Wrong network
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid dialog-style">
          <p>
            Please switch your wallet network to{" "}
            <b>
              {getNetworkName(
                network === "bsc"
                  ? Number(process.env.REACT_APP_WEB3_CHAIN_ID)
                  : Number(process.env.REACT_APP_WEB3_MATIC_CHAIN_ID)
              )}
            </b>{" "}
            to use the app on{" "}
            {getNetworkName(
              network === "bsc"
                ? Number(process.env.REACT_APP_WEB3_CHAIN_ID)
                : Number(process.env.REACT_APP_WEB3_MATIC_CHAIN_ID)
            )}{" "}
            chain.
          </p>
          <p>
            If you still encounter problems, you may want to switch to a
            different wallet
          </p>
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            {/* <CustomBtn
              onClick={(ev: React.MouseEvent<HTMLElement>) => {
                // props.onHide?.(ev);
                props.onHide && props.onHide();
                wallet.showWalletsModal();
              }}
            >
              Switch wallet
            </CustomBtn> */}
            <CustomBtn
              onClick={(ev: React.MouseEvent<HTMLElement>) => {
                // props.onHide?.(ev);
                props.onHide && props.onHide();
                onSwitchNetwork(network);
              }}
            >
              Switch Network
            </CustomBtn>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

export default UnsupportedChainModal;
