import React from 'react';
import "./assets/css/style.css";
import "./assets/css/main.css";
import "./App.css";

import Router from "./config/Router";

import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import Web3WalletProvider from "wallets/wallet";
// import Web3ContractsProvider from "./web3/contracts";

import rootReducer from "./slices";

function App() {
  const store = configureStore({ reducer: rootReducer });

  return (
    <Provider store={store}>
      <Web3WalletProvider>
        {/* <Web3ContractsProvider> */}

        <Router />

        {/* </Web3ContractsProvider> */}
      </Web3WalletProvider>
    </Provider>
  );
}

export default App;
