import React from 'react';
import './Community.css'
const Community = () => {
    return (
      <div >
        <div className="css-1s359ga ">
          <div className="governance-landing-community-bg" />
          <div className="governance-landing-community-body max-width">
            <div className="css-1qm13ev">
              <div data-bn-type="text" className="css-1ur9wqb">
                Join The Community
              </div>
              <div data-bn-type="text" className="css-19ese5w">
                Syncera's success is driven by its lively community. Join us and contribute to shaping the future.
              </div>
            </div>
            <div className="governance-landing-community-social">
              <div className="css-10nf7hq">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="css-aenbdp"
                >
                  <path
                    d="M9.13675 12.4328C9.13675 13.0522 9.591 13.5576 10.148 13.5576C10.7103 13.5576 11.1591 13.052 11.1591 12.4328C11.1705 11.8134 10.71 11.308 10.148 11.308C9.57993 11.308 9.13675 11.8136 9.13675 12.4328Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12.8691 12.4328C12.8691 13.0522 13.3245 13.5576 13.8808 13.5576C14.4488 13.5576 14.892 13.052 14.892 12.4328C14.9034 11.8134 14.4428 11.308 13.8808 11.308C13.3129 11.308 12.8691 11.8136 12.8691 12.4328Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM13.5968 7.75181C14.4092 7.88803 15.1875 8.13257 15.9203 8.46763H15.9224C17.1946 10.3253 17.8264 12.4272 17.5819 14.8472C16.6098 15.5573 15.6611 15.989 14.7351 16.2731C14.5076 15.9663 14.3033 15.6367 14.1272 15.2903C14.4624 15.1652 14.7811 15.0118 15.0872 14.8302C15.027 14.7872 14.9694 14.7409 14.9104 14.6936C14.8918 14.6787 14.873 14.6636 14.854 14.6485C13.0247 15.4949 11.0188 15.4949 9.1668 14.6485C9.09199 14.7107 9.01279 14.7736 8.93358 14.8302C9.24029 15.0118 9.55856 15.1652 9.89362 15.2903C9.7176 15.6368 9.5133 15.9663 9.28574 16.2731C8.3592 15.989 7.41613 15.5573 6.43893 14.8472C6.24016 12.7566 6.63783 10.6377 8.10919 8.46763C8.8369 8.13213 9.61514 7.88822 10.4332 7.75181C10.5287 7.92782 10.6488 8.1667 10.728 8.35403C11.5801 8.22894 12.438 8.22894 13.302 8.35403C13.3812 8.1667 13.4943 7.92782 13.5968 7.75181Z"
                    fill="currentColor"
                  />
                </svg>
                <a
                  data-bn-type="link"
                  href="https://discord.com/invite/e2XcjF5zX8"
                  target="_blank"
                  className="css-9wj43q"
                >
                  <div data-bn-type="text" className="css-1okghym">
                    Discord
                  </div>
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="governance-landing-page-explore-link css-1l1o9sq"
                >
                  <path
                    d="M11 5.00008C10.4477 5.00009 9.99999 5.4478 9.99999 6.00008C9.99999 6.55237 10.4477 7.00009 11 7.00008L15.5861 7L7.21385 15.2929C6.82015 15.6829 6.81864 16.3186 7.21047 16.7105C7.59968 17.0997 8.23024 17.1012 8.6213 16.7138L17.0004 8.41411L17.0011 13.0001C17.0012 13.5523 17.4489 14 18.0011 14C18.5534 14 19.0012 13.5522 19.0011 12.9999L19.0005 5.99992C19.0004 5.44767 18.5527 5 18.0005 5.00001L11 5.00008Z"
                    fill="#72768F"
                  />
                </svg>
              </div>
              <div className="css-10nf7hq">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="css-aenbdp"
                >
                  <path
                    d="M8.659 19c6.79 0 10.507-5.766 10.507-10.763 0-.16 0-.32-.01-.49A7.578 7.578 0 0021 5.79c-.663.3-1.376.5-2.127.6a3.824 3.824 0 001.63-2.1c-.713.44-1.503.75-2.352.92A3.6 3.6 0 0015.46 4c-2.04 0-3.698 1.699-3.698 3.787 0 .3.039.58.098.86-3.064-.15-5.786-1.669-7.61-3.957a3.858 3.858 0 00-.498 1.908c0 1.31.654 2.469 1.64 3.148a3.638 3.638 0 01-1.669-.47v.05c0 1.83 1.278 3.368 2.956 3.708a3.49 3.49 0 01-.976.13c-.234 0-.468-.02-.692-.07.468 1.509 1.834 2.598 3.453 2.628a7.284 7.284 0 01-4.585 1.62c-.293 0-.595-.01-.878-.05A10.206 10.206 0 008.659 19z"
                    fill="currentColor"
                  />
                </svg>
                <a
                  data-bn-type="link"
                  href="https://twitter.com/Syncera_io"
                  target="_blank"
                  className="css-9wj43q"
                >
                  <div data-bn-type="text" className="css-1okghym">
                    Twitter
                  </div>
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="governance-landing-page-explore-link css-1l1o9sq"
                >
                  <path
                    d="M11 5.00008C10.4477 5.00009 9.99999 5.4478 9.99999 6.00008C9.99999 6.55237 10.4477 7.00009 11 7.00008L15.5861 7L7.21385 15.2929C6.82015 15.6829 6.81864 16.3186 7.21047 16.7105C7.59968 17.0997 8.23024 17.1012 8.6213 16.7138L17.0004 8.41411L17.0011 13.0001C17.0012 13.5523 17.4489 14 18.0011 14C18.5534 14 19.0012 13.5522 19.0011 12.9999L19.0005 5.99992C19.0004 5.44767 18.5527 5 18.0005 5.00001L11 5.00008Z"
                    fill="#72768F"
                  />
                </svg>
              </div>
              <div className="css-10nf7hq">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="css-aenbdp"
                >
                  <path
                    d="M12 3c-4.97 0-9 4.03-9 8.99C3 16.96 7.03 21 12 21s9-4.04 9-9.01C21 7.03 16.97 3 12 3zm4.433 5.651c-.058.835-1.602 7.072-1.602 7.072s-.096.364-.432.374a.58.58 0 01-.442-.173c-.355-.297-1.16-.873-1.919-1.4-.028.028-.057.057-.096.086-.172.153-.431.374-.71.643-.105.096-.22.201-.335.316l-.01.01a2.007 2.007 0 01-.173.153c-.374.307-.412.048-.412-.086l.201-2.197v-.02l.01-.019c.01-.028.029-.038.029-.038s3.924-3.492 4.03-3.867c.009-.019-.02-.038-.068-.019-.259.086-4.778 2.946-5.277 3.262-.029.02-.115.01-.115.01l-2.197-.72s-.26-.105-.173-.345c.02-.048.048-.096.154-.163.489-.346 9-3.406 9-3.406s.24-.077.383-.03c.067.03.106.058.144.154.01.039.02.116.02.202 0 .048-.01.105-.01.201z"
                    fill="currentColor"
                  />
                </svg>
                <a
                  data-bn-type="link"
                  href="https://t.me/syncera_io"
                  target="_blank"
                  className="css-9wj43q"
                >
                  <div data-bn-type="text" className="css-1okghym">
                    Telegram
                  </div>
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="governance-landing-page-explore-link css-1l1o9sq"
                >
                  <path
                    d="M11 5.00008C10.4477 5.00009 9.99999 5.4478 9.99999 6.00008C9.99999 6.55237 10.4477 7.00009 11 7.00008L15.5861 7L7.21385 15.2929C6.82015 15.6829 6.81864 16.3186 7.21047 16.7105C7.59968 17.0997 8.23024 17.1012 8.6213 16.7138L17.0004 8.41411L17.0011 13.0001C17.0012 13.5523 17.4489 14 18.0011 14C18.5534 14 19.0012 13.5522 19.0011 12.9999L19.0005 5.99992C19.0004 5.44767 18.5527 5 18.0005 5.00001L11 5.00008Z"
                    fill="#72768F"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Community;
