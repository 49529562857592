import React from 'react';
import Title from '../../ProjectTitle/Title';
import './socialIcons.css'
const SocialIcons = () => {
    return (
      <div>
        <div className="shom">
          <Title  />
        </div>
        <div>
          <div>
          </div>
        </div>
      </div>
    );
}

export default SocialIcons;
