import React from "react";

import { Modal } from "react-bootstrap";
import styled from "styled-components";
// import MetamaskIcon from "../../assets/img/metamask-logo.svg";
// import walletCon from "../../assets/img/walletconnect.svg";

import { WalletConnector } from "wallets/types";
import { useWallet, WalletConnectors } from "wallets/wallet";

import "assets/css/modal.css"

const Wallet = styled.button`
  color: white;
  background: #0a0a0a;
  border-radius: 40px;
  width: 200px;
  height: 45px;
  margin: 20px auto;
  border: 1.5px solid #ff9600;
  text-align: center;
  font-size: 13px;
  padding: 10px 30px;
  & img {
    width: 24px;
    margin-right: 15px;
  }
`;
const ModelInfo = styled.div`
  & p {
    font-size: 13px;
    & a {
      color: #ff9600;
    }
  }
`;

const ConnectWalletModal: React.FunctionComponent<any> = (props) => {
  const wallet = useWallet();

  // const [ledgerModal, setLedgerModal] = React.useState<boolean>(false);

  function handleConnectorSelect(connector: WalletConnector) {
    if (wallet.isActive) {
      return;
    }

    // if (connector.id === 'ledger') {
    //   return setLedgerModal(true);
    // }

    return wallet.connect(connector);
  }

  return (
    <>
      <Modal
        className="model-connect"
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="modalHeader">
          <Modal.Title id="contained-modal-title-vcenter">
            Connect to a wallet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid dialog-style">
          {/* <ModelInfo>
            <div className="text-center">
              <Wallet
                onClick={() => handleConnectorSelect(WalletConnectors[0])}
              >
                MetaMask / BitKeep
              </Wallet>
              <Wallet
                onClick={() => handleConnectorSelect(WalletConnectors[1])}
              >
                Wallet Connect
              </Wallet>
            </div>
          </ModelInfo> */}
          <div>
            <div className="row flex-column" style={{ gap: "20px", paddingLeft: "10px", paddingRight: "10px" }}>
              <button id="connect-METAMASK" className="walletbutton" onClick={() => handleConnectorSelect(WalletConnectors[0])}>
                <div
                  color="#fff"
                  className="justify-content-between d-flex w-100 align-items-center"
                >
                  <span>MetaMask / Trust Wallet</span>
                  <img
                    src="https://app.dypius.com/images/wallets/metamask.svg"
                    width={50}
                    height={50}
                    alt="Icon"
                  />
                </div>
              </button>
              <button id="connect-COIN98" className="walletbutton" onClick={() => handleConnectorSelect(WalletConnectors[1])}>
                <div
                  color="#fff"
                  className="justify-content-between d-flex w-100 align-items-center"
                >
                  <span>WalletConnect</span>
                  <img
                    src="https://app.dypius.com/images/wallets/trustwallet.svg"
                    width={50}
                    height={50}
                    alt="Icon"
                  />
                </div>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConnectWalletModal;
